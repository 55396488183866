<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.home") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <h2 class="mb-2">{{ t("headings.recent_notifications") }}</h2>
    <div class="card mb-4">
      <custom-table
        :fields="notificationFields"
        :items="notifications"
        :loading="waiting"
        :pagination="false"
        :perPage="10"
        :searchable="false"
        :searchFields="[]"
        :showHeader="true"
        sortBy="createdAt"
        :ascending="false"
        :sortFields="notificationSortFields"
        @row-click="(id) => router.push(`/notifications/${id}`)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/notifications')"
              id="header-notifications-button"
              label="all_notifications"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(estate)="{ value }">
          {{ value?.name }}
        </template>
        <template #cell(type)="{ value }">
          <div :class="{ 'text-rose-600': value === 'deadline' }">
            {{ t("notification." + value) }}
          </div>
        </template>
        <template #cell(createdAt)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(sender)="{ value }">
          {{ value?.name }}
        </template>
      </custom-table>
    </div>
    <h2 class="mb-2">{{ t("headings.recent_bulletins") }}</h2>
    <div class="card mb-4">
      <custom-table
        :fields="bulletinFields"
        :items="bulletins"
        :loading="waiting"
        :pagination="false"
        :perPage="10"
        :searchable="false"
        :searchFields="[]"
        :showHeader="true"
        sortBy="createdAt"
        :ascending="false"
        :sortFields="bulletinSortFields"
        @row-click="(id) => router.push(`/bulletins/${id}`)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/bulletins')"
              id="header-bulletins-button"
              label="all_bulletins"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(createdAt)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(roles)="{ value }">
          <span v-for="(role, index) in value" :key="role.id"
            >{{ t(`roles.${role.label}`)
            }}{{ index < value.length - 1 ? ", " : null }}</span
          >
        </template>
        <template #cell(estates)="{ value }">
          <span v-for="(estate, index) in value" :key="estate"
            >{{ estate?.name
            }}{{ index < value.length - 1 ? ", " : null }}</span
          >
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, FormButton, CustomTable },
  setup() {
    const { locale, t } = useI18n();
    const router = useRouter();
    const store = useStore();

    const bulletins = computed(() => store.state.bulletin.bulletins);
    const notifications = computed(
      () => store.state.notification.notifications
    );
    const company = computed(() => store.state.company.company);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.authentication.waiting) return true;
      if (store.state.bulletin.waiting) return true;
      if (store.state.company.waiting) return true;
      if (store.state.notification.waiting) return true;
      return false;
    });

    const notificationFields = ref([
      {
        key: "createdAt",
        label: "date",
      },
      {
        key: "estate",
        label: "estate_name",
      },
      {
        key: "apartment",
        label: "apartment",
      },
      {
        key: "type",
        label: "type",
      },
      {
        key: "sender",
        label: "sender",
      },
    ]);
    const notificationSortFields = ref(["apartment", "createdAt", "type"]);

    const bulletinFields = ref([
      {
        key: "createdAt",
        label: "date",
      },
      {
        key: "estates",
        label: "estates",
      },
      {
        key: "topic",
        label: "topic",
      },
      {
        key: "roles",
        label: "groups",
      },
    ]);
    const bulletinSortFields = ref(["topic", "createdAt"]);

    onMounted(() => {
      if (!bulletins.value.length || bulletins.value.length > 10) {
        store.dispatch("bulletin/getLatestBulletins", user.value.companyId);
      }
      if (!notifications.value.length || notifications.value.length > 10) {
        store.dispatch(
          "notification/getLatestNotifications",
          user.value.companyId
        );
      }
    });

    return {
      bulletins,
      bulletinFields,
      bulletinSortFields,
      company,
      getLocaleDate,
      notifications,
      notificationFields,
      notificationSortFields,
      locale,
      router,
      t,
      waiting,
    };
  },
};
</script>
